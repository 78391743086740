html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
  
html, body, #root {
    height: 100%;

  }
main {
flex: 1; /* This makes the main content take up the remaining space */
}
.centercontainer {
height: 100vh;
}
.full-page {
min-height: 100vh;
}
#Homebody {
height: 100vh;

}

.container {
display: flex;
justify-content: top; /* Aligns items horizontally to the top */
align-items: top; /* Aligns items vertically to the top */
flex-direction: column;
justify-content: center;
}

.breadcrumb-item + .breadcrumb-item::before {
content: ">";
}

#UpdateLogContainer {
max-height: calc(100vh - 70px); /* Adjust this value to the available viewport height minus navbar height */
overflow-y: auto; /* Adds a vertical scrollbar if content overflows */
}
.wrapper {
display: flex;
flex-direction: column;
min-height: 100vh;
}
.footer {
background-color: #00000000;
text-align: center;
color: rgb(60, 30, 2);
padding: 10px 0;
font-family: 'Cormorant garamond', serif;
}
iframe {
width: 100%;
height: 600px; /* Adjust height as needed */
}
@media (max-width: 768px) {
iframe {
    height: 600px; /* Adjust height for smaller screens */
}
}


.centercontainer{
    height: 100vh; /* Full viewport height */
}
.Divider{
    height: 40vh; /* Full viewport height */
}
.PageHeader{
    height: 5vh; /* Full viewport height */
}
.Gap{
    height: 20vh; /* Full viewport height */ 
}


#root{
    width: 110%;


}