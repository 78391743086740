:root {
  --font-size-base: 2vw; /* Adjust this base value as needed */
  --golden-ratio: 1.618;

  --font-size-small: calc(var(--font-size-base) / var(--golden-ratio)); 
  --font-size-medium: calc(var(--font-size-base) * var(--golden-ratio));
  --font-size-large: calc(var(--font-size-medium) * var(--golden-ratio));
  --font-size-xlarge: calc(var(--font-size-large) * var(--golden-ratio));
  --font-size-xxlarge: calc(var(--font-size-xlarge) * var(--golden-ratio));
}

.SFont {font-size: var(--font-size-small);}
.MFont {font-size: var(--font-size-medium);}
.LFont{font-size: var(--font-size-large);}
.XLFont {font-size: var(--font-size-xlarge);}
.XXLFont {font-size: var(--font-size-xxlarge);}

.inline-elements > * {
    display: inline;
    margin-right: 10px; /* Optional: Add some spacing between elements */
}

.Font1{font-family: 'Cinzel decorative', serif;}
.Font2 {font-family: 'Cinzel', Georgia,  Times, serif;}
.Font3 { font-family: 'Cormorant garamond', serif;}

.FBR { font-weight: Bolder;}
.FB { font-weight: Bold;}